import React, { useState, useEffect, useRef } from 'react';
import { AutoComplete, Carousel, FloatButton, message, Button, Input } from "antd";
import { SearchOutlined, } from '@ant-design/icons';

import DynamicInfo from "../component/dynamicInfo";
import HairiButton from "../component/hairiButton";
import styles from "../layouts/MainLayout.module.scss";
import { useNavigate } from "react-router-dom";
import { getBannerList, getNewsList } from '@/services'
import * as echarts from 'echarts';
import chongqingJson from '../mapData/chongqing';
import zoomOut from '../image/icon/zoom-out.svg'
import zoomOutCli from '../image/icon/zoom-out-click.svg'
import zoomIn from '../image/icon/zoom-in.svg'
import zoomInCli from '../image/icon/zoom-in-click.svg'
import reset from '../image/icon/zoom-reset.svg'
import resetCli from '../image/icon/zoom-reset-click.svg'
import { ReactComponent as Answer } from '../image/icon/answer.svg'
import _ from 'lodash';
const chongqingAreas = [
    { id: 1, name: "万州区" },
    { id: 2, name: "涪陵区" },
    { id: 3, name: "渝中区" },
    { id: 4, name: "大渡口区" },
    { id: 5, name: "江北区" },
    { id: 6, name: "沙坪坝区" },
    { id: 7, name: "九龙坡区" },
    { id: 8, name: "南岸区" },
    { id: 9, name: "北碚区" },
    { id: 10, name: "綦江区" },
    { id: 11, name: "渝北区" },
    { id: 12, name: "巴南区" },
    { id: 13, name: "黔江区" },
    { id: 14, name: "长寿区" },
    { id: 15, name: "江津区" },
    { id: 16, name: "合川区" },
    { id: 17, name: "永川区" },
    { id: 18, name: "南川区" },
    { id: 19, name: "璧山区" },
    { id: 20, name: "铜梁区" },
    { id: 21, name: "潼南区" },
    { id: 22, name: "荣昌区" },
    { id: 23, name: "开州区" },
    { id: 24, name: "梁平区" },
    { id: 25, name: "武隆区" },
    { id: 26, name: "城口县" },
    { id: 27, name: "丰都县" },
    { id: 28, name: "垫江县" },
    { id: 29, name: "忠县" },
    { id: 30, name: "云阳县" },
    { id: 31, name: "奉节县" },
    { id: 32, name: "巫山县" },
    { id: 33, name: "巫溪县" },
    { id: 34, name: "石柱土家族自治县" },
    { id: 35, name: "秀山土家族苗族自治县" },
    { id: 36, name: "酉阳土家族苗族自治县" },
    { id: 37, name: "彭水苗族土家族自治县" },
    { id: 38, name: "大足区" }
];

const Home = () => {
    const navigate = useNavigate();
    const chartRef = useRef(null);
    const [zoomLevel, setZoomLevel] = useState(1);//放大倍率
    const [bannerListTop, setBannerListTop] = useState([]);//顶部轮播
    const [bannerList, setBannerList] = useState([]);//底部轮播
    const [newsList, setNewsList] = useState([]);//新闻列表
    const [matchList, setMatchList] = useState([]);//比赛列表
    const [currentPage, setCurrentPage] = useState(1);

    const [pageSize, setPageSize] = useState(10);

    const [totalPage, setTotalPage] = useState(0);

    const [searchVal, setSearchVal] = useState('');
    const [matchData, setMatchData] = useState([])
    const [searchData, setSearchData] = useState([])
    const [dataSource, setDataSource] = useState(chongqingAreas.map(area => area.name));


    const contentStyle = {
        height: '460px',
        color: '#e25fc0',
        lineHeight: '460px',
        textAlign: 'center',
        background: '#74787e',
        display: "flex",
        justifyContent: "center",
    };

    const resolvClick = () => {
        navigate('/message')
    }
    const handleTextClick = (id) => {
        navigate(`/announcementDetail/${id}`);
    };

    const getSource = async () => {
        const res = await getBannerList()
        if (res.code === 0) {
            setBannerListTop(res.data.banner)
            setBannerList(res.data.styleCompetition)
        } else {
            message.error(res.msg)
        }
    }

    const getNewsListSource = async ({ page, size, key, classification }) => {
        const params = { page, size, ...(key && { key }), ...(classification && { classification }) };
        const res = await getNewsList(params);
        if (res.code === 0) {
            // setNewsList(res.data.list)
            setTotalPage(res.data.total)
        }
    }

    // 新闻动态数据
    const getNewslistData = async () => {
        const params = { page: 1, size: 10, classification: 1 };
        const res = await getNewsList(params);
        if (res.code === 0) {
            setNewsList(res.data.list)
        }
    }
    // 赛事动态数据
    const getMatchlistData = async () => {
        const params = { page: 1, size: 10, classification: 2 };
        const res = await getNewsList(params);
        if (res.code === 0) {
            setMatchList(res.data.list)
        }
    }
    //赛事的数据
    const getMatchListSource = async () => {
        const params = { page: 1, size: 40, classification: 2 };
        const res = await getNewsList(params);
        if (res.code === 0) {
            const tempVal = res.data.list
            console.log(res.data.list, '数据为空');
            if (tempVal.length > 0) {
                setMatchData(res.data.list)
            }else{
                setMatchData([{area:"",create_time:"",id:"",title:"",name:"",}])
            }
        }
    }
    const handleZoomIn = () => {
        const myChart = echarts.getInstanceByDom(chartRef.current);
        const newZoomLevel = zoomLevel + 0.2;
        if (newZoomLevel <= 1.8) {
            myChart.setOption({
                series: [
                    {
                        zoom: newZoomLevel,
                    },
                ],
            });
            setZoomLevel(newZoomLevel);
        };

    }

    const handleZoomOut = () => {
        const myChart = echarts.getInstanceByDom(chartRef.current);
        const newZoomLevel = zoomLevel - 0.2;
        if (newZoomLevel >= 0.2) {
            myChart.setOption({
                series: [
                    {
                        zoom: newZoomLevel,
                    },
                ],
            });
            setZoomLevel(newZoomLevel);
        };
    }

    const handleReset = () => {
        const myChart = echarts.getInstanceByDom(chartRef.current);
        myChart.setOption({
            series: [
                {
                    zoom: 1,
                },
            ],
        });
        setZoomLevel(1);
    };

    const handleSearch = async (value) => {
        setSearchVal(value);
        const filteredOptions = chongqingAreas
            .map((area) => area.name)
            .filter((name) => name.toLowerCase().includes(value.toLowerCase()));
        setDataSource(filteredOptions);
    };

    const onSearchBtn = async () => {
        if (searchVal === '') {
            setSearchData([]);
        } else {
            const params = { page: 1, size: 1, area: searchVal, classification: 2 };
            const res = await getNewsList(params);
            if (res.code === 0) {
                setSearchData(res.data.list);
            }
        }
    }

    // 修改功能
    const tempData = matchData.map(item => {
        return { id: item.id, value: item.title, name: item.area };
    });

    const onJump = () => {
        if (searchData.length > 0) {
            navigate(`/announcementDetail/${searchData[0].id}`)
        } else {
            message.warning("暂无数据噢")
        }
    }

    useEffect(() => {
        getNewslistData()
        getMatchlistData()
        getSource()
        getNewsListSource(currentPage, pageSize)
        const fetchData = async () => {
            await getMatchListSource();
        }
        fetchData();
    }, []);

    useEffect(() => {
        // console.log(matchData,'matchData');
        
        if (matchData.length > 0) {
            const myChart = echarts.init(chartRef.current);
            echarts.registerMap('重庆', chongqingJson);
            const options = {
                series: [
                    {
                        name: '重庆市',
                        type: 'map',
                        map: '重庆',
                        data: [...tempData],
                        roam: true,
                        emphasis: {
                            label: {
                                show: true
                            },
                            itemStyle: {
                                // areaColor: '#56C7FF',
                                areaColor: {
                                    type: 'linear',
                                    x1: 0,
                                    y1: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [{
                                        offset: 0, color: '#56C7FF' // 起始颜色
                                    }, {
                                        offset: 1, color: '#0052D4' // 结束颜色
                                    }]
                                },
                                borderColor: '#00E7FF',
                                shadowBlur: 20,
                                borderWidth: 0,
                                shadowOffsetX: 5,
                                shadowOffsetY: 10,
                            }
                        }
                    }
                ],
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                        if (params.data) {
                            return params.data.name + ': ' + params.data.value;
                        } else {
                            return params.name + ': ' + '暂无比赛信息';
                        }
                    }
                },
                select: {
                    itemStyle: {
                        areaColor: '#3a91e0', // 选中状态的颜色与悬停状态的结束颜色相同
                        borderColor: '#00E7FF',
                        // shadowBlur: 20,
                        // borderWidth: 0,
                        // shadowOffsetX: 5,
                        // shadowOffsetY: 10,
                    },
                },
                geoJson: chongqingJson
            };

            myChart.setOption(options);
            // 处理地图选中状态
            function selectRegion(regionName) {
                myChart.dispatchAction({
                    type: 'mapSelect',
                    seriesIndex: 0,
                    name: regionName
                });
            }
            selectRegion(searchVal);
            myChart.on('click', function (params) {
                if (params && params.data && params.data.id) {
                    const id = params.data.id
                    navigate(`/announcementDetail/${id}`);
                }
            });
            return () => {
                myChart.dispose();
            };
        }
    }, [matchData, searchVal])
    return (
        <>
            <div
                style={{
                    width: '100%',
                    minWidth: "1200px",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 'auto',
                    flexDirection: "column"

                }}>
                <div
                    style={{
                        marginTop: "2px",
                        minHeight: 380,
                        width: '1200px',

                    }}
                >
                    <Carousel autoplay style={{ backgroundColor: "#CCCCCC", width: "100%", overflow: 'hidden' }}>
                        {
                            bannerListTop?.map((item, index) => (
                                <div key={index} >
                                    <div style={contentStyle}>
                                        <img src={process.env.REACT_APP_ENV === 'production' ? `https://oss.mlxx313.com/hairi/resource/${item.url}` : `https://oss.zmrobo.com/hairiTest/resource/${item.url}`} alt='轮播图' style={{ width: "100%", objectFit: 'cover', height: '100%', }} />
                                    </div>
                                </div>
                            ))
                        }
                    </Carousel>
                </div>
                <div
                    style={{
                        marginTop: "2px",
                        minHeight: 380,
                        width: '1200px',

                    }}
                >
                    {/* <Carousel autoplay style={{ backgroundColor: "pink",width:"100%" }}>
                        {bannerListTop?.map((item, index) => (
                            <div key={index} style={contentStyle}>
                                <img src={`https://oss.zmrobo.com/hairiTest/resource/${item.url}`} alt='轮播图' style={{ width: "100%", height: '460px', }} />
                            </div>
                        ))
                        }
                    </Carousel> */}
                    <div style={{ marginTop: '40px', minHeight: "auto", backgroundColor: "#F2FBFF", borderRadius: '10px', }}>
                        <p style={{ fontSize: 20, fontWeight: "bold", marginLeft: '40px', marginTop: '40px', paddingTop: "30px", textAlign: 'left' }}>新闻动态</p>
                        <div style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            {newsList.map((item, index) => (
                                <DynamicInfo messages={[item]} onTextClick={handleTextClick} key={index} />
                            ))}
                            <div style={{ marginBottom: "20px" }}>
                                <HairiButton onClick={() => navigate('/announcement')} buttonText="更多"></HairiButton>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '40px', minHeight: "auto", backgroundColor: "#F2FBFF", borderRadius: '10px', }}>
                        <p style={{ fontSize: 20, fontWeight: "bold", marginLeft: '40px', marginTop: '40px', paddingTop: "30px", textAlign: 'left' }}>最近赛事</p>
                        <div style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            {matchList.map((item, index) => (
                                <DynamicInfo messages={[item]} onTextClick={handleTextClick} key={index} />
                            ))}
                            <div style={{ marginBottom: "20px" }}>
                                <HairiButton onClick={() => navigate('/announcement')} buttonText="更多"></HairiButton>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '40px', minHeight: "auto", backgroundColor: "#F2FBFF", borderRadius: '10px', }}>
                        <p style={{ fontSize: 20, fontWeight: "bold", marginLeft: '40px', marginTop: '40px', paddingTop: "30px", textAlign: 'left' }}>重庆赛事</p>
                        <Input.Group compact >
                            <AutoComplete
                                style={{ width: '340px', marginLeft: '40px', }}
                                options={dataSource.map((option) => ({
                                    value: option,
                                }))}
                                onSelect={(value) => setSearchVal(value)}
                                onSearch={(value) => handleSearch(value)}
                                placeholder="万州区"

                            >
                            </AutoComplete>
                            <Button
                                type="text"
                                icon={<SearchOutlined />}
                                onClick={onSearchBtn}
                                style={{ backgroundColor: "#fff", border: '1px solid #ccc', borderLeft: 'none', }}
                            />
                        </Input.Group>
                        {searchVal !== '' && <p style={{ marginTop: 17, marginLeft: '40px', color: "#8995A4" }}>搜索结果:<a onClick={onJump}>{searchData.length === 0 ? <span style={{ color: 'red' }}>该地区暂无比赛信息</span> : searchData[0].title}</a></p>
                        }<div style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <div id="main" style={{ width: '100%', height: '600px' }} ref={chartRef}></div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div
                                className="icon-container"
                                onClick={handleZoomIn}
                                style={{ cursor: 'pointer', marginRight: "20px", marginBottom: "20px" }}
                                onMouseOver={(e) => e.currentTarget.firstChild.src = zoomInCli}
                                onMouseOut={(e) => e.currentTarget.firstChild.src = zoomIn}
                            >
                                <img src={zoomIn} className="icon" />
                            </div>
                            <div
                                className="icon-container"
                                onClick={handleZoomOut}
                                style={{ cursor: 'pointer', marginRight: "20px", marginBottom: "20px" }}
                                onMouseOver={(e) => e.currentTarget.firstChild.src = zoomOutCli}
                                onMouseOut={(e) => e.currentTarget.firstChild.src = zoomOut}
                            >
                                <img src={zoomOut} className="icon" />
                            </div>
                            <div
                                className="icon-container"
                                onClick={handleReset}
                                style={{ cursor: 'pointer', marginRight: "20px", marginBottom: "20px" }}
                                onMouseOver={(e) => e.currentTarget.firstChild.src = resetCli}
                                onMouseOut={(e) => e.currentTarget.firstChild.src = reset}
                            >
                                <img src={reset} className="icon" />
                            </div>
                        </div>
                    </div>
                    <div style={{
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        marginTop: "20px"

                    }}>
                        <p style={{ margin: 10, padding: 10, marginLeft: "546px", fontSize: "20px", fontWeight: "bold" }}>赛事风采</p>
                    </div>
                </div>

            </div>
            <div className={styles.slider} >
                {bannerList?.length <= 10 ? (
                    <div
                        className={styles.slidesTwo}
                        style={{
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        {bannerList.map((item, index) => (
                            <img
                                key={index}
                                src={process.env.REACT_APP_ENV === 'production' ? `https://oss.mlxx313.com/hairi/resource/${item.url}` : `https://oss.zmrobo.com/hairiTest/resource/${item.url}`}
                                alt={`Image ${index + 1}`}
                                style={{ width: '260px', height: '150px' }}
                                onError={(e) => e.target.style.display = 'none'}
                            />
                        ))}
                    </div>
                ) : (
                    <div>
                        <div className={styles.slider}>
                            <div
                                className={styles.slides}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    paddingBottom: '10px'
                                }}
                            >
                                {bannerList.slice(0, 10).map((item, index) => (
                                    <img
                                        key={index}
                                        src={process.env.REACT_APP_ENV === 'production' ? `https://oss.mlxx313.com/hairi/resource/${item.url}` : `https://oss.zmrobo.com/hairiTest/resource/${item.url}`} alt={`Image ${index + 1}`}
                                        style={{ width: '260px', height: '150px', borderRadius: '10px' }}
                                        onError={(e) => e.target.style.display = 'none'}
                                    />
                                ))}
                            </div>
                        </div>
                        <div
                            className={styles.slidesTwo}
                            style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            {bannerList.slice(10).map((item, index) => (
                                <img
                                    key={index + 10}
                                    src={process.env.REACT_APP_ENV === 'production' ? `https://oss.mlxx313.com/hairi/resource/${item.url}` : `https://oss.zmrobo.com/hairiTest/resource/${item.url}`}
                                    alt={`Image ${index + 11}`}
                                    style={{ width: '260px', height: '150px', borderRadius: '10px' }}
                                    onError={(e) => e.target.style.display = 'none'}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </div>

            <FloatButton.Group
                shape="circle"
                style={{
                    right: 48,
                    top: 380,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <FloatButton
                    icon={<Answer style={{ width: '26px', height: '26px', marginLeft: "-4px", }} />}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '60px',
                        height: '60px',
                    }}
                    onClick={resolvClick}
                />
                <p style={{ whiteSpace: "nowrap", color: "#8995A4" }}>咨询答疑</p>
                <div style={{ marginTop: '16px' }}>
                    <FloatButton.BackTop visibilityHeight={200} />
                </div>
            </FloatButton.Group>
        </>
    );
};

export default Home;